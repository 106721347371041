import { Fragment } from 'react';

import { Row } from '@breeze-platform-ui/layout';

import styles from './form-progress-stepper.module.css';
import { StepIcon } from './step-icon';
import { type ProgressStep, progressSteps } from './step-names';

type Props = {
  step: ProgressStep;
};

export const FormProgressStepper = ({ step }: Props) => {
  const activeIndex = progressSteps.findIndex((s) => s === step);

  return (
    <Row alignMain="between" alignCross="center" width="100%">
      {progressSteps.map((stepName, index) => {
        return (
          <Fragment key={stepName}>
            {!!index && <div className={styles.connector} />}
            <StepIcon
              activeIndex={activeIndex}
              currentIndex={index}
              step={stepName}
            />
          </Fragment>
        );
      })}
    </Row>
  );
};
