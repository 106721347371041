/* eslint-disable max-statements */
import React, { useMemo, useRef, useState, useCallback } from 'react';

import { environment } from '~app/environment';

import { AdditionalDocument } from '~widgets/application-form/additional-document';
import { AdditionalPersonalDetails } from '~widgets/application-form/additional-personal-details';
import { AlternativeContacts } from '~widgets/application-form/alternative-contacts';
import {
  AdditionalEmploymentDetails,
  EmploymentDetails,
} from '~widgets/application-form/employment';
import type { EmployedWithCompanyStatusValue } from '~widgets/application-form/employment/lib';
import { Finances } from '~widgets/application-form/finances';
import { progressIcons } from '~widgets/application-form/form-progress-stepper';
import {
  IdDocumentNumber,
  IdDocumentType,
} from '~widgets/application-form/id-document';
import type { LoanCalculatorFormValues } from '~widgets/application-form/loan-calc';
import { LoanCalculator } from '~widgets/application-form/loan-calc';
import { PermanentAddress } from '~widgets/application-form/permanent-address';
import { PersonalDetails } from '~widgets/application-form/personal-details';
import { ResidentialAddress } from '~widgets/application-form/residential-address';
import { FormLoadingPage } from '~widgets/form-loading-page';

import { AuthGuard } from '~features/auth-guard/auth-guard';
import { usePhonesUnicityValidation } from '~features/phones-unicity';

import type { Application } from '~entities/application';
import { useUpdateApplicationMutation } from '~entities/application';
import { isAuthorisedAtom } from '~entities/auth';
import type { Order } from '~entities/order';
import type { Person } from '~entities/person';
import { useUpdatePersonMutation } from '~entities/person';

import { useOnMountEffect } from '~shared/hooks';
import { StepFlowStrategy } from '~shared/lib/step-flow-strategy';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { useAtomValue, useSetAtom } from 'jotai';

import type {
  FormValues,
  StepName,
  FormErrors,
  ApplicationStepName,
  PersonStepName,
} from '../../lib';
import {
  stepNames,
  formatApplicationScreenValues,
  formatPersonScreenValues,
  applicationStepNames,
  personStepNames,
} from '../../lib';
import { StepFlowMachine } from '../../model';
import {
  formErrorsAtom,
  isInitializationInProgress,
  formValuesAtom,
  updateFormAtom,
  useRecoveredInitialStepListener,
} from '../../model/form-atoms';

type Props = {
  order: Order;
  applicationId?: string;
  clientPhone?: string;
  onInitialize: (application: Partial<Application>) => void;
  onSubmit: (skipValidation?: boolean) => void;
};

export const LoanApplicationForm: React.FC<Props> = ({
  order,
  applicationId,
  clientPhone,
  onSubmit,
  onInitialize,
}) => {
  const isFinishedSubmitted = useRef(false);

  const { mutate: updatePerson, finalMutateAsync: finalUpdatePersonAsync } =
    useUpdatePersonMutation();
  const {
    mutate: updateApplication,
    finalMutateAsync: finalUpdateApplicationAsync,
  } = useUpdateApplicationMutation();

  const [currentStep, setCurrentStep] = useState<StepName>(
    stepNames.loanDetails
  );
  const formValues = useAtomValue(formValuesAtom);
  const formErrors = useAtomValue(formErrorsAtom);
  const isFormInitializing = useAtomValue(isInitializationInProgress);
  const updateForm = useSetAtom(updateFormAtom);
  const isAuthorised = useAtomValue(isAuthorisedAtom);

  const stepFlowStrategy = useMemo(
    () =>
      new StepFlowStrategy<StepName>(StepFlowMachine(stepNames.loanDetails)),
    []
  );
  useOnMountEffect(() => {
    // Preload all progress step icons to avoid blinking
    Object.values(progressIcons).forEach(({ active, inactive }) => {
      new Image().src = active;
      new Image().src = inactive;
    });
  });

  useRecoveredInitialStepListener(
    useCallback(
      (get, _set, step, prevStep) => {
        if (step && step !== prevStep) {
          setCurrentStep(step);
          stepFlowStrategy.forwardTo(step, get(formValuesAtom));
        }
      },
      [stepFlowStrategy]
    )
  );

  const goNext = (currentStepValues: FormValues[StepName]) => {
    const nextStep = stepFlowStrategy.next({
      ...formValues,
      [currentStep]: currentStepValues,
    });
    setCurrentStep(nextStep);
  };

  const goBack = () => {
    const prevScreenName = stepFlowStrategy.prev();
    setCurrentStep(prevScreenName);
  };

  const buildApplication = useCallback(
    (
      currentStepValues: Partial<FormValues[ApplicationStepName]>,
      currentStepErrors: Partial<FormErrors[ApplicationStepName]>
    ) => {
      const newFormValues = {
        ...formValues,
        [currentStep]: currentStepValues,
      };
      const newFormErrors = {
        ...formErrors,
        [currentStep]: currentStepErrors,
      };
      const futureSteps = stepFlowStrategy
        .getFullFlow(newFormValues)
        .filter(
          (step) => step in applicationStepNames
        ) as ApplicationStepName[];

      return formatApplicationScreenValues(
        futureSteps,
        newFormValues,
        newFormErrors
      );
    },
    [formErrors, formValues, currentStep, stepFlowStrategy]
  );

  const buildPerson = useCallback(
    (
      currentStepValues: Partial<FormValues[PersonStepName]>,
      currentStepErrors: Partial<FormErrors[PersonStepName]>
    ): Person => {
      const newFormValues = {
        ...formValues,
        [currentStep]: currentStepValues,
      };
      const newFormErrors = {
        ...formErrors,
        [currentStep]: currentStepErrors,
      };

      const futureSteps = stepFlowStrategy
        .getFullFlow(newFormValues)
        .filter((step) => step in personStepNames) as PersonStepName[];

      const person = formatPersonScreenValues(
        futureSteps,
        newFormValues,
        newFormErrors
      );

      return {
        ...person,
        metaData: {
          applicationId,
          appVersion: environment.FORM_VERSION,
          lastStep: currentStep,
        },
      };
    },
    [formErrors, formValues, currentStep, stepFlowStrategy, applicationId]
  );

  const handleFormChange = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>,
    shouldUpdateMetaData?: boolean
  ) => {
    if (isFinishedSubmitted.current || !applicationId) {
      return;
    }

    const isPersonDetailsStep = currentStep in personStepNames;

    if (isPersonDetailsStep || shouldUpdateMetaData) {
      const newPerson = buildPerson(
        currentStepValues as Partial<FormValues[PersonStepName]>,
        currentStepErrors as Partial<FormErrors[PersonStepName]>
      );
      updatePerson({ ...newPerson, applicationId });
    }

    if (!isPersonDetailsStep) {
      const newApplication = buildApplication(
        currentStepValues as Partial<FormValues[ApplicationStepName]>,
        currentStepErrors as Partial<FormErrors[ApplicationStepName]>
      );

      updateApplication({
        ...newApplication,
        applicationId,
      });
    }
  };

  const handleFormStateUpdate = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>
  ) => {
    updateForm({
      step: currentStep,
      value: currentStepValues,
      errors: currentStepErrors,
    });
  };

  const handleGoBack = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: FormErrors[StepName]
  ) => {
    if (applicationId) {
      const values = currentStep in personStepNames ? currentStepValues : {};
      const errors = currentStep in personStepNames ? currentStepErrors : {};
      const newPerson = buildPerson(
        values as Partial<FormValues[PersonStepName]>,
        errors as Partial<FormErrors[PersonStepName]>
      );
      const history = stepFlowStrategy.getHistory();
      const prevStep =
        history.length < 2 ? undefined : history[history.length - 2];

      updatePerson({
        ...newPerson,
        applicationId,
        metaData: { ...newPerson.metaData, lastStep: prevStep },
      });
    }

    handleFormStateUpdate(currentStepValues, currentStepErrors);
    goBack();
  };

  const handleStepSubmit = (currentStepValues: FormValues[StepName]) => {
    handleFormStateUpdate(currentStepValues, {});
    handleFormChange(currentStepValues, {}, true);
    goNext(currentStepValues);
  };

  const handleLoanCalcSubmit = (
    currentStepValues: LoanCalculatorFormValues
  ) => {
    handleStepSubmit(currentStepValues);
    if (isAuthorised && !applicationId) {
      onInitialize(buildApplication(currentStepValues, {}));
    }
  };

  const handleFinalSubmit = async (
    currentStepValues: FormValues[StepName],
    skipValidation?: boolean
  ) => {
    handleFormStateUpdate(currentStepValues, {});

    if (isFinishedSubmitted.current) {
      return;
    }
    isFinishedSubmitted.current = true;

    try {
      await finalUpdatePersonAsync({
        ...buildPerson(
          currentStepValues as Partial<FormValues[PersonStepName]>,
          {}
        ),
        applicationId: nonNullableValue(applicationId),
      });
      await finalUpdateApplicationAsync({
        ...buildApplication(
          currentStepValues as Partial<FormValues[ApplicationStepName]>,
          {}
        ),
        applicationId: nonNullableValue(applicationId),
      });
      onSubmit(skipValidation);
    } catch {
      isFinishedSubmitted.current = false;
    }
  };

  const { additionalEmploymentDetails, alternativeContacts } = formValues;

  const { validatePhone, handlePhoneUpdate } = usePhonesUnicityValidation({
    personal: clientPhone,
    work: additionalEmploymentDetails?.workPhone,
    alternative: alternativeContacts?.number,
    additional: alternativeContacts?.additionalPhone,
  });

  const handleLogin = useCallback(
    (phone: string) => {
      if (!applicationId) {
        onInitialize(buildApplication({}, {}));
      }
      handlePhoneUpdate(phone, 'personal');
    },
    [onInitialize, buildApplication, applicationId, handlePhoneUpdate]
  );

  if (isFormInitializing) {
    return <FormLoadingPage />;
  }

  const currentIdType =
    formValues[stepNames.idDocumentType]?.typeMain ||
    formValues[stepNames.idDocumentType]?.typeOther;

  const handleCancelLogin = () => {
    stepFlowStrategy.reset();
    setCurrentStep(stepNames.loanDetails);
  };

  return (
    <AuthGuard
      phoneNumber={clientPhone}
      skipCheck={currentStep === 'loanDetails'}
      onLogin={handleLogin}
      onCancel={handleCancelLogin}
    >
      <Stepper current={currentStep}>
        <Step name={stepNames.loanDetails}>
          <ScrollIntoView>
            <LoanCalculator
              order={order}
              initialValue={formValues[stepNames.loanDetails]}
              onSubmit={handleLoanCalcSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.personalDetails}>
          <ScrollIntoView>
            <PersonalDetails
              initialValue={formValues[stepNames.personalDetails]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalPersonalDetails}>
          <ScrollIntoView>
            <AdditionalPersonalDetails
              initialValue={formValues[stepNames.additionalPersonalDetails]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.residentialAddress}>
          <ScrollIntoView>
            <ResidentialAddress
              initialValue={formValues[stepNames.residentialAddress]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.permanentAddress}>
          <ScrollIntoView>
            <PermanentAddress
              initialValue={formValues[stepNames.permanentAddress]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.employmentDetails}>
          <ScrollIntoView>
            <EmploymentDetails
              initialValue={formValues[stepNames.employmentDetails]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalEmploymentDetails}>
          <ScrollIntoView>
            <AdditionalEmploymentDetails
              validatePhoneUnicity={validatePhone}
              initialValue={formValues[stepNames.additionalEmploymentDetails]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              employmentStatus={
                nonNullableValue(
                  formValues.employmentDetails?.employmentStatus
                ) as EmployedWithCompanyStatusValue
              }
              onPhoneUpdate={handlePhoneUpdate}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.finances}>
          <ScrollIntoView>
            <Finances
              initialValue={formValues[stepNames.finances]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.alternativeContacts}>
          <ScrollIntoView>
            <AlternativeContacts
              validatePhoneUnicity={validatePhone}
              initialValue={formValues[stepNames.alternativeContacts]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              onPhoneUpdate={handlePhoneUpdate}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.idDocumentType}>
          <ScrollIntoView>
            <IdDocumentType
              initialValue={formValues[stepNames.idDocumentType]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        {/* showed for documents with type [UMID, SSS ID] -> we should not show additional document screen for them  */}
        <Step name={stepNames.mainIdDocumentNumber}>
          <ScrollIntoView>
            <IdDocumentNumber
              initialValue={formValues[stepNames.mainIdDocumentNumber]}
              currentIdType={nonNullableValue(currentIdType)}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleFinalSubmit}
            />
          </ScrollIntoView>
        </Step>
        {/* showed for documents with other id types -> we should show additional document screen for them */}
        <Step name={stepNames.otherIdDocumentNumber}>
          <ScrollIntoView>
            <IdDocumentNumber
              initialValue={formValues[stepNames.otherIdDocumentNumber]}
              currentIdType={nonNullableValue(currentIdType)}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalDocument}>
          <ScrollIntoView>
            <AdditionalDocument
              initialValue={formValues[stepNames.additionalDocument]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleFinalSubmit}
            />
          </ScrollIntoView>
        </Step>
      </Stepper>
    </AuthGuard>
  );
};
/* eslint-enable max-statements */
