import React from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { FormProgressStepper } from '~widgets/application-form/form-progress-stepper';

import { useFormInitialize } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { CheckboxGroup, InputMoney, Select } from '@breeze/rhf-adapters';

import {
  MONTHLY_INCOME_REQUIRED_ERROR,
  MONTHLY_PAYMENT_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../constants/field-errors';
import {
  DEBT_INFO_QA_LABEL,
  FINANCIAL_SITUATION_FIELD_NAME,
  MONTHLY_INCOME_FIELD_NAME,
  MONTHLY_PAYMENT_FIELD_NAME,
} from '../constants/field-names';
import type { BooleanFinanceFieldsValue } from '../lib/boolean-finance-fields';
import type { FinancialSituationValue } from '../lib/financial-situation-options';
import { financialSituationOptions } from '../lib/financial-situation-options';
import { getFinancesDetailsOptions } from '../lib/get-finances-details-options';

export interface FormValues {
  financialSituation: FinancialSituationValue;
  personMonthlyIncome: string;
  debtInfo?: BooleanFinanceFieldsValue[];
  monthlyPaymentAmount?: string;
}

type Props = Readonly<{
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues) => void;
  onFieldCompleted?: (
    value: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
}>;

export const Finances: React.FC<Props> = ({
  onSubmit,
  initialValue,
  onPrev,
  onFieldCompleted,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
  });
  const { control, watch, getValues, handleSubmit } = methods;

  useFormInitialize(methods, initialValue);

  const debtInfo = watch('debtInfo') ?? [];

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const hasOutstandingDebt = debtInfo.includes('haveOutstandingDebtNow');

  const financesDetailsOptions = getFinancesDetailsOptions(debtInfo);

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <div className="mb-5">
          <ScreenTitle
            title="Tell us about your finances"
            icon={<FormProgressStepper step="finances" />}
          />
        </div>
        <Controller
          control={control}
          name="financialSituation"
          rules={{ required: SELECT_REQUIRED_ERROR }}
          render={(fieldProps) => (
            <Select
              {...fieldProps}
              options={financialSituationOptions}
              label={FINANCIAL_SITUATION_FIELD_NAME}
            />
          )}
        />
        <Controller
          control={control}
          name="personMonthlyIncome"
          rules={{
            required: MONTHLY_INCOME_REQUIRED_ERROR,
          }}
          render={(fieldProps) => (
            <InputMoney
              {...fieldProps}
              label={MONTHLY_INCOME_FIELD_NAME}
              currency="PHP"
              currencyInValue
              symbolPosition="prefix"
              digitGroupingSeparator=","
              maxLength={11} // Includes digitGroupingSeparator
              message="Salary, remittances, renting out housing, etc."
            />
          )}
        />
        <Controller
          control={control}
          name="debtInfo"
          render={(fieldProps) => (
            <CheckboxGroup
              {...fieldProps}
              qaLabel={DEBT_INFO_QA_LABEL}
              field={{
                ...fieldProps.field,
                onChange(values: BooleanFinanceFieldsValue[]) {
                  fieldProps.field.onChange(
                    values.includes('creditUsage')
                      ? values
                      : values.filter((value) => value === 'haveBankAccount')
                  );
                },
              }}
              wide
              vertical
              options={financesDetailsOptions}
            />
          )}
        />
        {hasOutstandingDebt && (
          <Controller
            control={control}
            name="monthlyPaymentAmount"
            shouldUnregister
            rules={{
              required: MONTHLY_PAYMENT_REQUIRED_ERROR,
            }}
            render={(fieldProps) => (
              <InputMoney
                maxLength={11} // Includes digitGroupingSeparator
                label={MONTHLY_PAYMENT_FIELD_NAME}
                currency="PHP"
                currencyInValue
                symbolPosition="prefix"
                digitGroupingSeparator=","
                {...fieldProps}
              />
            )}
          />
        )}
      </Screen>
    </form>
  );
};
