export const progressSteps = [
  'personal',
  'home',
  'work',
  'finances',
  'contacts',
  'identity',
] as const;

export type ProgressStep = (typeof progressSteps)[number];
