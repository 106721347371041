import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { FormProgressStepper } from '~widgets/application-form/form-progress-stepper';

import {
  yesNoOptions,
  type YesNoOptionValue,
} from '~shared/constants/yes-no-options';
import { useFormInitialize } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Select, InputDate, Input, Multiselect } from '@breeze/rhf-adapters';

import {
  ABROAD_FIELD_NAME,
  BIRTHDATE_FIELD_NAME,
  EDUCATION_FIELD_NAME,
  GENDER_FIELD_NAME,
  MARITAL_STATUS_FIELD_NAME,
  PLACE_OF_BIRTH_FIELD_NAME,
  PREFERRED_LANGUAGE,
} from '../constants';
import {
  DATE_OF_BIRTH_REQUIRED,
  MULTISELECT_REQUIRED,
  PLACE_OF_BIRTH_REQUIRED,
  SELECT_REQUIRED,
} from '../constants/field-errors';
import type {
  CivilStatusValue,
  EducationLevelValue,
  GenderValue,
  PreferedLanguageValue,
} from '../lib';
import {
  civilStatusOptions,
  educationLevelOptions,
  genderOptions,
  preferredLanguageOptions,
} from '../lib';

export interface FormValues {
  birthDate: string;
  placeOfBirth: string;
  gender: GenderValue;
  civilStatus: CivilStatusValue;
  educationLevel: EducationLevelValue;
  abroadTraveller: YesNoOptionValue;
  preferredLanguages: PreferedLanguageValue[];
}

type Props = Readonly<{
  initialValue?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
}>;

export const AdditionalPersonalDetails: React.FC<Props> = ({
  initialValue,
  onFieldCompleted,
  onPrev,
  onSubmit,
}) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const { control, handleSubmit, getValues } = methods;

  useFormInitialize(methods, initialValue);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <div className="mb-5">
          <ScreenTitle
            title="Tell us more about you"
            subtitle="Just a few more personal details"
            icon={<FormProgressStepper step="personal" />}
          />
        </div>

        <Controller
          name="birthDate"
          control={control}
          rules={{
            required: DATE_OF_BIRTH_REQUIRED,
            validate: combineValidators(
              validators.isDate(),
              validators.ageRange({
                minAge: 0,
                maxAge: 130,
              })
            ),
          }}
          render={(fieldProps) => (
            <InputDate label={BIRTHDATE_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="placeOfBirth"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: PLACE_OF_BIRTH_REQUIRED,
              }),
              validators.maxLength({ maxLength: 150 }),
              validators.filipinoGeo()
            ),
          }}
          render={(fieldProps) => (
            <Input label={PLACE_OF_BIRTH_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="gender"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={GENDER_FIELD_NAME}
              options={genderOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="civilStatus"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={MARITAL_STATUS_FIELD_NAME}
              options={civilStatusOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="educationLevel"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={EDUCATION_FIELD_NAME}
              options={educationLevelOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="abroadTraveller"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={ABROAD_FIELD_NAME}
              options={yesNoOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="preferredLanguages"
          control={control}
          rules={{
            required: MULTISELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Multiselect
              label={PREFERRED_LANGUAGE}
              options={preferredLanguageOptions}
              {...fieldProps}
            />
          )}
        />
      </Screen>
    </form>
  );
};
