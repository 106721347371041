import styles from './form-progress-stepper.module.css';
import { type ProgressStep } from './step-names';

import checkMarkIcon from '../images/check-mark.webp';
import contactsActiveImg from '../images/contacts-active.webp';
import contactsInactiveImg from '../images/contacts-inactive.webp';
import financeActiveImg from '../images/finance-active.webp';
import financeInactiveImg from '../images/finance-inactive.webp';
import homeActiveImg from '../images/home-active.webp';
import homeInactiveImg from '../images/home-inactive.webp';
import identityCardActiveImg from '../images/identity-active.svg';
import identityCardInactiveImg from '../images/identity-inactive.svg';
import personalActiveImg from '../images/personal-active.webp';
import workActiveImg from '../images/work-active.webp';
import workInactiveImg from '../images/work-inactive.webp';

type ProgressIcons = {
  [name in ProgressStep]: {
    active: string;
    inactive: string;
  };
};

export const progressIcons: ProgressIcons = {
  personal: {
    active: personalActiveImg,
    inactive: personalActiveImg,
  },
  home: {
    active: homeActiveImg,
    inactive: homeInactiveImg,
  },
  work: {
    active: workActiveImg,
    inactive: workInactiveImg,
  },
  finances: {
    active: financeActiveImg,
    inactive: financeInactiveImg,
  },
  contacts: {
    active: contactsActiveImg,
    inactive: contactsInactiveImg,
  },
  identity: {
    active: identityCardActiveImg,
    inactive: identityCardInactiveImg,
  },
};

type Props = {
  activeIndex: number;
  currentIndex: number;
  step: ProgressStep;
};

export const StepIcon = ({ activeIndex, currentIndex, step }: Props) => {
  if (currentIndex < activeIndex) {
    return (
      <div className={styles.finished}>
        <img
          src={progressIcons[step].active}
          width="100%"
          height="100%"
          alt={`${step}-completed`}
        />
        <img
          src={checkMarkIcon}
          className={styles.finishedMark}
          alt="completed-icon"
        />
      </div>
    );
  }
  if (currentIndex === activeIndex) {
    return (
      <img
        src={progressIcons[step].active}
        className={styles.current}
        alt={`${step}-active`}
      />
    );
  }
  return (
    <img
      className={styles.following}
      src={progressIcons[step].inactive}
      alt={`${step}-inactive`}
    />
  );
};
